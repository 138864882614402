<template>
  <ValidationProvider
    v-if="validateRule"
    :rules="validateWithRequired"
    v-slot="{ errors, changed }"
  >
    <SfCheckbox
      :name="name"
      :required="validateRule && validateRule.includes('required')"
      :error-message="errorMessage"
      hint-message=""
      :disabled="disabled"
      :selected="selected"
      :valid="changed ? selected === true : errors.length === 0"
      @change="handleChange"
      class="custom-checkbox"
    >
      <template #label>
        <span class="custom-checkbox__label" :class="{ disabled: disabled }">
          {{
            `${$t(label)}${
              validateRule && validateRule.includes('required') ? '*' : ''
            }`
          }}
          <SfLink
            v-if="name === 'privacy-policy-checkbox'"
            link="/privacy"
            target="_blank"
          >
            Privacy Policy
          </SfLink>
        </span>
      </template>
      <template #show-error-message>
        <div
          class="custom-checkbox__error-message"
          v-if="errors.length > 0 || (changed && !selected)"
        >
          <span class="custom-checkbox__error-text">
            {{ $t(errorMessage) }}
          </span>
        </div>
        <div v-else />
      </template>
      <template #checkmark>
        <CustomCheckmark :selected="selected" :disabled="disabled" />
      </template>
    </SfCheckbox>
  </ValidationProvider>
  <SfCheckbox
    v-else
    :name="name"
    hint-message=""
    :disabled="disabled"
    :selected="selected"
    @change="handleChange"
    class="custom-checkbox"
  >
    <template #label>
      <slot name="custom-checkbox-label">
        <span
          class="custom-checkbox__label"
          :class="{ disabled: disabled, 'search-checkbox': isSearchCheckbox }"
        >
          {{ $t(label) }}
          <SfLink
            v-if="name === 'privacy-policy-checkbox'"
            :link="localePath('/privacy')"
          >
            Privacy Policy
          </SfLink>
        </span>
      </slot>
    </template>
    <template #checkmark>
      <CustomCheckmark
        :selected="selected"
        :disabled="disabled"
        :is-search-checkbox="isSearchCheckbox"
      />
    </template>
  </SfCheckbox>
</template>

<script>
import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { SfCheckbox, SfLink } from '@storefront-ui/vue';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import CustomCheckmark from './CustomCheckmark.vue';

extend('required', {
  ...required,
  message: 'Required field',
});

export default defineComponent({
  name: 'CustomCheckbox',
  components: {
    CustomCheckmark,
    SfCheckbox,
    SfLink,
    ValidationProvider,
  },
  model: {
    prop: 'selectedValue',
  },
  props: {
    label: {
      type: String,
      default: 'Ho letto e compreso la ',
    },
    name: {
      type: String,
      default: 'privacy-policy-checkbox',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: 'Required field',
    },
    validateRule: {
      type: String,
      default: '',
    },
    selectedValue: {
      type: Boolean,
      default: false,
    },
    isSearchCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selected = ref(props.selectedValue);

    const validateWithRequired = computed(() => {
      if (props.validateRule.includes('required')) {
        const validationObject = {};
        props.validateRule.split('|').forEach((e) => {
          if (e === 'required') {
            validationObject[e] = { allowFalse: false };
          } else if (e === '') {
            validationObject[e] = { allowFalse: true };
          } else {
            validationObject[e] = true;
          }
        });
        return validationObject;
      }
      return props.validateRule;
    });

    const handleChange = (e) => {
      selected.value = e;
      emit('checkboxChange', e);
      emit('input', e);
    };

    return {
      selected,
      handleChange,
      validateWithRequired,
    };
  },
});
</script>

<style lang="scss">
.custom-checkbox {
  width: fit-content;
  .sf-checkbox {
    &__container {
      .custom-checkbox {
        &__label {
          padding-left: 1rem;
          @include paragraph-regular;
          &.disabled {
            color: var(--c-dark-gray);
          }
        }
      }
    }
  }
  &.has-error {
    .sf-checkbox__container {
      .custom-checkbox__checkbox {
        outline: var(--red-border);
      }
    }
  }
}
</style>
