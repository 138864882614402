























import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { SfQuantitySelector } from '@storefront-ui/vue';
import { useTranslation } from '~/composables';

export default defineComponent({
  name: 'CustomQuantitySelector',
  components: {
    SfQuantitySelector,
  },
  props: {
    quantity: {
      type: Number,
    },
    minQuantity: {
      type: Number,
      default: null,
    },
    maxQuantity: {
      type: Number,
      default: null,
    },
    step: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectedQuantity = ref<number>(props.quantity);
    const hasErrorMessage = ref<boolean>(false);
    const { $gt } = useTranslation('translations');

    const stepValue = computed(() => (props.step > 1 ? props.step : 1));

    const showQtyErrorMessage = (quantity: number) => {
      hasErrorMessage.value = !(quantity % stepValue.value === 0);
      if (hasErrorMessage.value) {
        setTimeout(() => {
          hasErrorMessage.value = false;
        }, 3500);
      }
    };
    const operandClicked = (operand: string) => {
      if (operand === '+') {
        selectedQuantity.value += stepValue.value;
      }
      if (operand === '-') {
        selectedQuantity.value -= stepValue.value;
      }
      showQtyErrorMessage(selectedQuantity.value);
      selectedQuantity.value =
        selectedQuantity.value % stepValue.value === 0
          ? selectedQuantity.value
          : stepValue.value;
      emit('change', selectedQuantity.value);
    };

    const changedQty = (quantity) => {
      const quantityInt = Number.parseInt(quantity, 10);
      if (quantityInt === selectedQuantity.value) return;
      if (quantityInt === 0) {
        selectedQuantity.value = quantityInt;
        emit('change', selectedQuantity.value, false);
        return;
      }
      showQtyErrorMessage(quantityInt);
      selectedQuantity.value =
        quantityInt % stepValue.value === 0 ? quantityInt : stepValue.value;
      emit('change', selectedQuantity.value, hasErrorMessage.value);
    };

    return {
      operandClicked,
      changedQty,
      hasErrorMessage,
      selectedQuantity,
      $gt,
    };
  },
});
